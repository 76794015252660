import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import { useThemeUI } from "theme-ui"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Count from "../../../../../components/count"
import QuoteBox from "../../../../../components/quote-box"
import BarChart from "../../../../../components/bar-chart"
import Tooltip from "../../../../../components/tooltip"
import Small from "../../../../../components/small"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const context = useThemeUI()
  const { theme } = context

  const chartData = [
    {
      id: "düster",
      name: "düster",
      value: 5,
    },
    {
      id: "Dämmerung",
      name: "Dämmerung",
      value: 12,
    },
    {
      id: "Monde",
      name: "Monde",
      value: 18,
    },
    {
      id: "Gestirn",
      name: "Gestirn",
      value: 21,
    },
    {
      id: "dunkel",
      name: "dunkel",
      value: 34,
    },
    {
      id: "trüb",
      name: "trüb",
      value: 37,
    },
    {
      id: "Sterne",
      name: "Sterne",
      value: 67,
      color: "yellow",
    },
    {
      id: "Nacht",
      name: "Nacht",
      value: 134,
    },
  ]

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/nachtgesaenge/brot-und-wein" />
      }
    >
      <Seo title="Nachtgesänge" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Nachtgesänge
            </Heading>
          </Stack>
          <Paragraph>
            In einem Brief an seinen Verleger Friedrich Wilmanns bezeichnete
            Hölderlin die sieben für die Veröffentlichung im Taschenbuch
            vorbereiteten Gedichte, zu denen auch ›Hälfte des Lebens‹ zählte,
            einmal als ›Nachtgesänge‹.
          </Paragraph>
          <QuoteBox author="Brief an Friedrich Wilmans um Weihnachten 1803">
            Ich bin eben an der Durchsicht einiger Nachtgesänge für Ihren
            Almanach. Ich wollte Ihnen aber sogleich antworten, damit kein
            Sehnen in unsere Beziehung kommt. [...] <br />
            <br />
            Übrigens sind Liebeslieder immer müder Flug, denn so weit sind wir
            noch immer, troz der Verschiedenheit der Stoffe; ein anders ist das
            hohe und reine Frohloken vaterländischer Gesänge. Das Prophetische
            der{" "}
            <Tooltip content="Epische Darstellung von Jesu Christis Leben">
              Messiade
            </Tooltip>{" "}
            und einiger Oden ist Ausnahme. Ich bin sehr begierig, wie Sie die
            Probe einiger größern lyrischen Gedicht aufnehmen werden.
          </QuoteBox>
          <Paragraph>
            Über die Liebe wollte Hölderlin in diesen Gedichten also nicht
            schreiben. Vielmehr ging es ihm um eine bestimmte Form, die Form des
            Gesangs, die in der Antike ganz selbstverständlich zur Lyrik
            dazugehörte. Die Aufführung von Gedichten wurde von Musik und Tanz
            begleitet. Doch nicht um irgendeinen Gesang ging es Hölderlinum,
            sondern um einen Nachtgesang. Im Jahr 1800 erschienen ›Hymnen an die
            Nacht‹ von Novalis. Nur zwei Jahre später verfasst Clemens Brentano
            ›Der Spinnerin Nachtlied‹, das allerdings erst 16 Jahre darauf
            veröffentlicht wurde. Goethes ›Wandrers Nachtlied‹ war dagegen
            bereits 1776 entstanden und 1789 gedruckt worden. Die Nacht war das
            große Thema der Zeit. Auch in Hölderlins Gedichten spielt sie eine
            unübersehbar große Rolle.
          </Paragraph>
          <Paragraph>
            <Count>134</Count> Mal kommt die Nacht in Hölderlins Gedichten vor,{" "}
            <Count>12</Count> Mal die Dämmerung, <Count>34</Count> Mal wird es
            dunkel, <Count>37</Count> Mal trüb und <Count>5</Count> Mal düster.{" "}
            <Count>67</Count>
            Sterne und <Count>18</Count> Monde bevölkern den Kosmos seiner
            Gedichte. <Count>21</Count> Mal wird das Gestirn erwähnt.
          </Paragraph>
          <Stack space={3}>
            <BarChart
              height="420px"
              data={chartData}
              layout="horizontal"
              colors={theme.colors["chart"]}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              isInteractive={false}
              enableGridY={false}
              margin={{ top: 0, right: 60, bottom: 0, left: 80 }}
              indexBy="name"
            />
            <Small color="whisper">
              Die Himmelskörper in Hölderlins Gedichten, gezählt von Heike
              Gfrereis, Vera Hildebrandt und Roland Kamzelak für die Ausstellung
              ›Hölderlin, Celan und die Sprachen der Poesie‹. Daten aus dem
              Deutschen Literaturarchiv Marbach.
            </Small>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
